import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { List } from 'state-domains/domain';

import { buildConfigurationListUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken, convertToCamel } from '../../../utils';

export const saveList = (id: string, payload: any): Observable<List> => {
    const url = buildConfigurationListUrl(id);
    return sendRequestWithXSRFToken(url, payload, 'PUT').pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const convertedList = convertToCamel<List>(response);
            return observableOf(convertedList);
        }),
    );
};
